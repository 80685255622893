import React, { useRef, useState, RefObject, FocusableElement } from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Text
} from '@chakra-ui/react';

interface ProductDescriptionModalProps {
  isOpen: boolean,
  isLoading: boolean,
  setIsLoading: Function,
  onClose: () => void,
  onSubmit: (completionEvent, text) => void,
  finalRef: RefObject<FocusableElement>
}

const ProductDescriptionPostModal : React.FC<ProductDescriptionModalProps> = (props) => {

  const { isOpen, onClose, finalRef, onSubmit, isLoading, setIsLoading } = props;
  const initialRef = useRef();
  const [theme, setTheme] = useState('');
  const [name, setName] = useState('');

  const isDisabled = theme.length < 50 || name.length < 5;
  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ação avançada</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Descrição de produto</FormLabel>
              <Text mb={4}>Crie uma descrição para um novo produto. A descrição é rica em detalhes a partir de um texto inicial.</Text>

              <FormLabel>Nome Produto</FormLabel>
              <Input 
                ref={initialRef} 
                placeholder='eChair' 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormHelperText>Mínimo de 5 caracteres.</FormHelperText>

              <FormLabel mt={3}>Sobre o produto</FormLabel>
              <Textarea 
                placeholder='Descreva as principais características sobre o produto' 
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
              />
              <FormHelperText>Mínimo de 50 caracteres.</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button 
              colorScheme="teal" 
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                onSubmit('PRODUCT_DESCRIPTION', {name: name, description: theme});
              }}
            >Gerar texto</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ProductDescriptionPostModal;