import React, { MouseEventHandler } from 'react';

import {
  Box,
  Button,
  IconButton,
  Flex
} from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons'



interface CreateNewCardProps {
  onClick: MouseEventHandler,
  imageUrl: string,
  imageAlt: string,
  title: string,
  description: string,
  isDemo: boolean,
  badges: Array<string>,
  height: string,
  style: any
}

const CreateNewCard: React.FC<CreateNewCardProps> = (props) => {
  return (
    <>
      <Box 
        w='48%' 
        maxW={"sm"} 
        borderWidth='1px' 
        borderRadius='lg' 
        overflow='hidden' 
        borderStyle="dashed" 
        h={props.height}
        mt={`10px !important`}
        cursor="pointer"
        onClick={props.onClick}
        style={props.style}
      >
        <Flex p='4' pt="2" h="100%" flexDir="column">
          <Box
            mt='1'
            fontWeight='semibold'
            as='h4'
            lineHeight='tight'
            fontSize="sm"
            isTruncated
          >
            {props.title}
          </Box>
          <Box
            fontSize="sm"
          >
            {props.description}
          </Box>
          <Box mt="auto">
            <Button variant={"outline"} borderStyle="dashed" size="xs" style={props.style}>
              Create new
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default CreateNewCard;