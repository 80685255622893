import React, { useState, useEffect, useRef } from 'react';

import Container from '../components/Container';
import Header from '../components/Header';
import TextGenerationCard from '../components/TextGenerationCard';
import DocumentCard from '../components/Editor/DocumentCard';
import CreateNewCard from '../components/Editor/CreateNewCard';
import { 
	Editor, 
	EditorState, 
	RichUtils, 
	Modifier, 
	convertToRaw,
  convertFromRaw
} from 'draft-js';
import TreeChart from '../components/Editor/Tree';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import { graphql } from 'gatsby'
import { stateToMarkdown } from "draft-js-export-markdown";

import {
	Flex,
	Stack,
	Button,
	Box,
	Grid,
	GridItem,
	Divider,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Input,
	Tabs,
	Tab,
	TabList,
	TabPanels,
	TabPanel,
	Switch,
	Text,
	HStack,
	FormControl,
	FormLabel,
	FormHelperText,
	useToast,
	useDisclosure,
	Tooltip
} from '@chakra-ui/react';
import { FaBold, FaUnderline, FaItalic, FaCode, FaPenFancy, FaHeading } from "react-icons/fa"
import { RiParagraph } from "react-icons/ri"
import { BsArrowsExpand, BsArrowsCollapse } from "react-icons/bs"
import { ChevronDownIcon } from '@chakra-ui/icons'
import Graph from '../components/Editor/Graph';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import 'draft-js/dist/Draft.css';

import BlogPostModal from '../components/Editor/Modals/BlogPostModal';
import ListPostModal from '../components/Editor/Modals/TopicListModal';
import ProductDescriptionModal from '../components/Editor/Modals/ProductDescriptionModal';
import StoryModal from '../components/Editor/Modals/StoryModal';
import ChildrenStoryModal from '../components/Editor/Modals/ChildrenStoryModal';
import PromptModal from '../components/Editor/Modals/PromptModal';

const defaultBlogPost = '{"blocks":[{"key":"cmfmn","text":"\\nComo negociar salários em tecnologia\\n\\nSalário é um tema complicado. Afinal, quanto vale o seu tempo? Como negociar um salário em Tecnologia?\\n\\nAbaixo estão algumas dicas para você negociar um salário que esteja de acordo com o seu valor:\\n\\n1. Avalie seu mercado\\n\\nAntes de qualquer coisa, é importante avaliar o seu mercado. Quanto vale o seu tempo e qual é o valor médio do salário para a posição que você ocupa?\\n\\n2. Compare suas habilidades com as do mercado\\n\\nAlém de avaliar seu mercado, é importante comparar as suas habilidades com as do mercado. Será que você está cobrando o mesmo valor que as outras pessoas nesta posição?\\n\\n3. Negocie\\n\\nA última dica é: não tenha medo de negociar! Lembre-se que o salário é um tema negociável. Apresente uma proposta clara e objetiva para a empresa.\\n\\nConclusão\\n\\nNegociar um salário não é uma tarefa fácil, mas é possível seguir algumas dicas. É importante avaliar o seu mercado e as suas habilidades para comparar com as do mercado. Não tenha medo de negociar e apresente uma proposta clara e objetiva.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":1,"length":36,"style":"H1"},{"offset":239,"length":22,"style":"BOLD"},{"offset":413,"length":46,"style":"BOLD"},{"offset":630,"length":11,"style":"BOLD"},{"offset":790,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}'

const styleMap = {
	'STRIKETHROUGH': {
		textDecoration: 'line-through',
	},
	'H1': {
		fontSize: '30px'
	}
};

interface DocumentType {
	title: string,
	description: string,
	isDemo: boolean,
	contentRaw: string
}

const EditorRoute: React.FC = () => {
	const [editorState, setEditorState] = useState(
		() => EditorState.createEmpty(),
	);
	const markdown = stateToMarkdown(
		editorState.getCurrentContent()
	)
	const editorRef = useRef(null);
	const _onBoldClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
	}

	const _onItalicClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
	}

	const _onUnderlineClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
	}

	const _onCodeClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, 'CODE'));
	}

	const _onHeaderClick = () => {
		setEditorState(RichUtils.toggleInlineStyle(editorState, 'H1'));
	}

	const _onModelClick = async (completionEvent, text = '') => {
		const myText = text;
		try {
			// const selectedText = editorState.getCurrentContent().getPlainText()
			let selected = getFragmentFromSelection(editorState);
			selected = selected ? selected.map(x => x.getText()).join('\n') : ''

			const body = {
				prompt: myText || selected,
				completionEvent: completionEvent,
				apiKey: apiKey
			}
			
			const res = await fetch('/.netlify/functions/modelCompletion', {
				method: 'POST',
				body: JSON.stringify(body)
			})
			
			setIsLoadingModal(false);
			setLoadingExpand(false);
			setLoadingImprove(false);
			setLoadingParagraph(false);
			setLoadingShorten(false);

			switch (res.status) {
				case 401:
					toast({
						title: 'Acesso da API não autorizado.',
						description: "Verifique se você adicionou a chave de API na aba de configurações",
						status: 'error',
						duration: 4500,
						isClosable: true,
					})
					return;
				case 200:
					break;
				default:
					toast({
						title: 'Erro inesperado.',
						description: "Entre em contato com os desenvolvedores para entender o que aconteceu",
						status: 'error',
						duration: 4500,
						isClosable: true,
					})
					return;
			}

			const text = await res.text()
			const contentModified = Modifier.insertText(
				editorState.getCurrentContent(),
				editorState.getCurrentContent().getSelectionAfter(),
				`\n${text}`)
			const newEditorState = EditorState.push(editorState, contentModified, "insert-text");
			setEditorState(newEditorState)
			onCloseBlogPostModal();
			onCloseChildrenStoryModal();
			onCloseProductDescriptionModal();
			onCloseStoryModal();
			onCloseTopicListModal();
		} catch (error) {
			// console.log(error);
		}
	}

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.focus();
		}
	}, [editorRef]);

	useEffect(() => {
		const rawEditorData = getSavedEditorData(0);
		if (rawEditorData !== null) {
			const contentState = convertFromRaw(rawEditorData);
			setEditorState(EditorState.createWithContent(contentState))
		} else {
			const updatedDocuments = [
				{
					title: 'Meu primeiro texto',
					description: 'Este é o seu primeiro texto para teste',
					isDemo: false,
					contentRaw: convertToRaw(EditorState.createEmpty().getCurrentContent())
				},
				{
					title: 'Blog Serenpit',
					description: 'Este texto já tem um conteúdo base',
					isDemo: true,
					contentRaw: JSON.parse(defaultBlogPost)
				}
			]
			localStorage.setItem("documents", JSON.stringify(updatedDocuments));
		}
		const documents : Array<DocumentType> = JSON.parse(localStorage.getItem('documents'));
		setDocuments(documents);
	}, []);

	const onChangeEditor = (newEditorState) => {
		const currentContentState = editorState.getCurrentContent()
		const newContentState = newEditorState.getCurrentContent()
		if ((currentContentState !== newContentState)) {
			// There was a change in the content
			const raw = convertToRaw(newEditorState.getCurrentContent());
			saveEditorContent(raw)
		} else {			
		}
		setEditorState(newEditorState);
	}

	const saveEditorContent = (data) => {
		const documents : string = localStorage.getItem('documents');
		let updatedDocuments : Array<DocumentType> = JSON.parse(documents);
		
		updatedDocuments[documentIndex]['contentRaw'] = data;
		localStorage.setItem("documents", JSON.stringify(updatedDocuments));
		setDocuments(updatedDocuments);
  }

  const getSavedEditorData = (index) => {
    const savedData = localStorage.getItem("documents");
    return savedData !== null ? JSON.parse(savedData)[index ?? documentIndex]['contentRaw'] : null;
  }

	const [documentIndex, setDocumentIndex] = useState(0);
	const [apiKey, setApiKey] = useState('');
	const [loadingExpand, setLoadingExpand] = useState(false);
	const [loadingShorten, setLoadingShorten] = useState(false);
	const [loadingImprove, setLoadingImprove] = useState(false);
	const [loadingParagraph, setLoadingParagraph] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [documents, setDocuments] = useState([]);
	const toast = useToast();

	const { 
		isOpen: isOpenPromptModal, 
		onOpen: onOpenPromptModal, 
		onClose: onClosePromptModal
	} = useDisclosure();
	const { 
		isOpen: isOpenBlogPostModal, 
		onOpen: onOpenBlogPostModal, 
		onClose: onCloseBlogPostModal 
	} = useDisclosure();
	const { 
		isOpen: isOpenTopicListModal, 
		onOpen: onOpenTopicListModal, 
		onClose: onCloseTopicListModal 
	} = useDisclosure();
	const { 
		isOpen: isOpenProductDescriptionModal, 
		onOpen: onOpenProductDescriptionModal, 
		onClose: onCloseProductDescriptionModal 
	} = useDisclosure();
	const { 
		isOpen: isOpenStoryModal, 
		onOpen: onOpenStoryModal, 
		onClose: onCloseStoryModal 
	} = useDisclosure();
	const { 
		isOpen: isOpenChildrenStoryModal, 
		onOpen: onOpenChildrenStoryModal, 
		onClose: onCloseChildrenStoryModal 
	} = useDisclosure();

	const handleApiKeyChange = (e) => setApiKey(e.target.value)
	const lengthTextSelected = getFragmentFromSelection(editorState)?.map(x => x.getText()).join('\n').length;
	const isButtonModelDisabled50 = isNaN(lengthTextSelected) ? true : lengthTextSelected < 50;
	const isButtonModelDisabled25 = isNaN(lengthTextSelected) ? true : lengthTextSelected < 25;
	return (
		<Container>
			<Header />
			<Grid templateColumns='3fr 2fr' gap={6}>
				<GridItem w='100%' height={600}>
					<Stack direction='row' spacing={4} align='center' marginBottom={2} w='100%' wrap="wrap">
						<IconButton aria-label='Heading' icon={<FaHeading />} onClick={_onHeaderClick} size="sm" />
						<IconButton aria-label='Bold' icon={<FaBold />} onClick={_onBoldClick} size="sm" />
						<IconButton aria-label='Rephrase' icon={<FaUnderline />} onClick={_onUnderlineClick} size="sm" />
						<IconButton aria-label='Italic' icon={<FaItalic />} onClick={_onItalicClick} size="sm" />
						<IconButton aria-label='Código' icon={<FaCode />} onClick={_onCodeClick} size="sm" />
					</Stack>
					<Divider />
					<Stack direction='row' spacing={4} align='center' marginY={2} w='100%' wrap={"wrap"}>
						<Tooltip label='Selecione pelo menos 25 caracteres' shouldWrapChildren>
							<Button
								isLoading={loadingExpand}
								leftIcon={<BsArrowsExpand />}
								onClick={() => {
									setLoadingExpand(true)
									_onModelClick("EXPASION")
								}}
								isDisabled={isButtonModelDisabled25}
							>
								Expandir
							</Button>
						</Tooltip>
						<Tooltip label='Selecione pelo menos 50 caracteres' shouldWrapChildren>
							<Button
								isLoading={loadingShorten}
								leftIcon={<BsArrowsCollapse />}
								onClick={() => {
									setLoadingShorten(true)
									_onModelClick("SHORTEN")
								}}
								isDisabled={isButtonModelDisabled50}
							>
								Encurtar
							</Button>
						</Tooltip>
						<Tooltip label='Selecione pelo menos 25 caracteres' shouldWrapChildren>
							<Button
								isLoading={loadingImprove}
								leftIcon={<FaPenFancy />}
								onClick={() => {
									setLoadingImprove(true)
									_onModelClick("IMPROVE")
								}}
								isDisabled={isButtonModelDisabled25}
								>
								Aperfeiçoar
							</Button>
						</Tooltip>
						<Tooltip label='Selecione pelo menos 50 caracteres' shouldWrapChildren>
							<Button
								isLoading={loadingParagraph}
								leftIcon={<RiParagraph />}
								onClick={() => {
									setLoadingParagraph(true)
									_onModelClick("PARAGRAPH")
								}}
								isDisabled={isButtonModelDisabled50}
							>
								Criar Parágrafo
							</Button>
						</Tooltip>
					</Stack>
					<Box w='100%' h='100%' py={4}>
						<Editor
							ref={editorRef}
							customStyleMap={styleMap}
							editorState={editorState}
							onChange={onChangeEditor}
							style={{ width: '100%' }}
							spellCheck={true}
						/>
					</Box>
				</GridItem>
				<GridItem w='100%'>
					<Tabs variant='line' colorScheme='green'>
						<TabList>
							<Tab>Ações Avançadas</Tab>
							<Tab>Documentos</Tab>
							<Tab>Configurações</Tab>
						</TabList>
						<TabPanels>
							<TabPanel
								paddingLeft={0}
								paddingRight={0}
							>
								<HStack
									wrap="wrap"
									align="start"
									justifyContent="space-between"
									spacing={0}
								>
									<CreateNewCard
										title="Escreva seu prompt"
										description='Dê uma instrução genérica e veja o resultado. Qualquer instrução é aceita.'
										height="200px"
										onClick={onOpenPromptModal}
									/>
									<TextGenerationCard
										title='Criar um Blog Post'
										description='Crie uma estrutura de blog post a partir de um tema inicial.'
										badges={['COMPLEX']}
										bannerColor="orange"
										onClick={onOpenBlogPostModal}
									/>
									<TextGenerationCard
										title='Listar tópicos'
										description='Crie uma lista sobre os mais diversos conteúdos.'
										badges={['SIMPLE']}
										bannerColor="teal"
										onClick={onOpenTopicListModal}
									/>
									<TextGenerationCard
										title='Descrição de produto'
										description='Crie uma descrição para um novo produto. A descrição é rica em detalhes a partir de um texto inicial.'
										badges={['COMPLEX']}
										bannerColor="teal"
										onClick={onOpenProductDescriptionModal}
									/>
									<TextGenerationCard
										title='Conte uma história'
										description='Crie uma história a partir de palavras-chave.'
										badges={['SIMPLE']}
										bannerColor="purple"
										onClick={onOpenStoryModal}
									/>
									<TextGenerationCard
										title='Resumir para uma criança'
										description='Transforme um texto complexo em algo simples.'
										badges={['SIMPLE']}
										onClick={onOpenChildrenStoryModal}
										bannerColor="purple"
									/>
								</HStack>
							</TabPanel>
							<TabPanel paddingX={0}>
								<HStack
									wrap="wrap"
									alignItems="start"
									justifyContent="space-between"
									spacing={0}
								>
									<CreateNewCard
										title="Crie um novo documento"
										description='Você pode criar uma quantidade indefinida de documentos.'
										height="150px"
										style={{cursor: "not-allowed"}}
									/>
									{documents.map((document, index) => (
										<DocumentCard
											key={index}
											isActive={index == documentIndex}
											title={document.title}
											description={document.description}
											bannerColor="gray"
											badges={["Private"]}
											isDemo={document.isDemo}
											onClick={() => {
												setDocumentIndex(index)
												const rawEditorData = getSavedEditorData(index);
												const contentState = convertFromRaw(rawEditorData);
												setEditorState(EditorState.createWithContent(contentState))
											}}
										/>))}
									
								</HStack>
							</TabPanel>
							<TabPanel paddingX={0}>
								<Stack
									mt="10px"
									borderWidth='1px'
									borderRadius='lg'
									p="6"
								>
									<FormControl isRequired>
										<FormLabel htmlFor='first-name'>Chave de API</FormLabel>
										<Input
											id='first-name'
											placeholder='CHAVE_DE_TESTE'
											onChange={handleApiKeyChange}
											value={apiKey}
										/>
										<FormHelperText>A chave de API é atualizada automaticamente.</FormHelperText>
									</FormControl>
								</Stack>
							</TabPanel>
						</TabPanels>
					</Tabs>
				</GridItem>
			</Grid>
			<BlogPostModal 
				onClose={onCloseBlogPostModal} 
				isOpen={isOpenBlogPostModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
			<ListPostModal
				onClose={onCloseTopicListModal} 
				isOpen={isOpenTopicListModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
			<ProductDescriptionModal
				onClose={onCloseProductDescriptionModal} 
				isOpen={isOpenProductDescriptionModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
			<StoryModal
				onClose={onCloseStoryModal} 
				isOpen={isOpenStoryModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
			<ChildrenStoryModal
				onClose={onCloseChildrenStoryModal} 
				isOpen={isOpenChildrenStoryModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
			<PromptModal
				onClose={onClosePromptModal} 
				isOpen={isOpenPromptModal}
				finalRef={editorRef}
				onSubmit={_onModelClick}
				isLoading={isLoadingModal}
				setIsLoading={setIsLoadingModal}
			/>
		</Container>
	)
}

export default EditorRoute;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter : {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;