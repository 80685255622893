import React, { MouseEventHandler } from 'react';

import {
  Box,
  Badge,
  Text,
  Heading,
  Stack,
  Divider,
  Flex
} from '@chakra-ui/react';

interface DocumentCardProps {
  onClick: MouseEventHandler,
  imageUrl: string,
  imageAlt: string,
  title: string,
  description: string,
  isDemo: boolean,
  badges: Array<string>,
  bannerColor: string,
  isActive: boolean
}

const DocumentCard: React.FC<DocumentCardProps> = (props) => {
  return (
    <>
      <Box
        w='48%'
        maxW={"sm"}
        borderWidth='1px'
        borderRadius='lg'
        overflow='hidden'
        h="150px"
        marginBottom="30px"
        mt={`10px !important`}
        cursor="pointer"
        onClick={props.onClick}
      >
        <Box w={'100%'} h="10px" bg={props.isActive ? 'teal' : props.bannerColor} />
        <Flex p='4' pb="6" direction="column" h='100%'>
          <Box
            fontWeight='semibold'
            as='h4'
            lineHeight='tight'
            fontSize="sm"
            isTruncated
          >
            {props.title}
          </Box>
          <Box
            fontSize="sm"
          >
            {props.description}
          </Box>

          <Flex mt="auto">
            {props.isDemo && <Badge borderRadius='full' px='2' colorScheme='teal' mr="2">
              Demo
            </Badge>
            }
            {props.badges?.map(badgeText => (<Badge key={badgeText} borderRadius='full' px='2' mr="2" variant="subtle">
              {badgeText}
            </Badge>))}
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default DocumentCard;