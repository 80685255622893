import React, { useRef, useState, useCallback, RefObject, FocusableElement } from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Text
} from '@chakra-ui/react';

import TagInput from '../../TagInput';

interface StoryModalProps {
  isOpen: boolean,
  isLoading: boolean,
  setIsLoading: Function,
  onClose: () => void,
  onSubmit: (completionEvent, text) => void,
  finalRef: RefObject<FocusableElement>
}

const StoryModal : React.FC<StoryModalProps> = (props) => {

  const { isOpen, onClose, finalRef, onSubmit, isLoading, setIsLoading } = props;
  const initialRef = useRef();
  
  const [tags, setTags] = useState([])
  const handleTagsChange = useCallback((event: SyntheticEvent, tags: string[]) => {
    setTags(tags)
  }, [])

  const isDisabled = tags.length < 5;
  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ação avançada</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Conte uma história</FormLabel>
              <Text mb={4}>Crie uma história a partir de palavras-chave.</Text>

              <FormLabel>Tópicos</FormLabel>
              <TagInput
                tags={tags} onTagsChange={handleTagsChange}
              />
              <FormHelperText>Mínimo de 5 palavras.</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button 
              colorScheme="teal" 
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                onSubmit('STORY', { tags });
              }}
            >Gerar texto</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default StoryModal;